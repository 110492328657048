import { AbilityBuilder, Ability } from '@casl/ability'
import * as libs from './libs'

import * as customSurvey from './permissions/custom-survey'
import * as engagement from './permissions/engagement'
import * as performance from './permissions/performance'
import * as management from './permissions/management'
import * as profile from './permissions/profile'
import * as growth from './permissions/growth'

export const ability = new Ability()
export function abilityHelper(permissions = [], additional = false) {
  const isArray = Array.isArray(permissions)
  const isStringArray = permissions.every(key => typeof key === 'string')

  if (!isArray || !isStringArray) {
    permissions = []
  }

  let ability_ = new AbilityBuilder()
  if (additional) {
    ability_ = ability
  }

  permissions.forEach(key => ability_.can('access', key))
  ability.update(ability_.rules)
}

export const NestedGeneralPermissions = Object.keys({
  ...customSurvey,
  ...engagement,
  ...performance,
  ...management,
  ...profile,
  ...growth,
}).reduce((acc, key) => {
  const nestedPermissions = libs.splitUnderlineToNestedObject(key)
  return libs.mergeObjectsRecursively(acc, nestedPermissions)
}, {})
