/**
 * @type {import('vuex').Module<any, any>}
 */
export const SideModule = {
  namespaced: false,

  state: () => ({}),

  getters: {},

  actions: {},

  mutations: {},
}
