const D_LANGUAGE =
  process?.env?.VUE_APP_I18N_LOCALE ||
  process?.env?.VUE_APP_I18N_FALLBACK_LOCALE ||
  'pt'

const ENUM_LANGUAGES = {
  pt: 'pt-BR',
  en: 'en-US',
  es: 'es-AR',
}

/**
 * Calculates the language code to be used in an API request based on the input language code.
 * @param {string} lang - The input language code.
 * @returns {string} The language code to be used in the API request.
 */
export function calcLanguageToApi(lang) {
  const langAsLocale = calcLanguageToLocale(lang)
  return ENUM_LANGUAGES[langAsLocale] || ENUM_LANGUAGES[D_LANGUAGE]
}

/**
 * Generates a language code based on the input language.
 * @param {string} lang - The language to generate the code for.
 * @return {string} The generated language code.
 */
export function calcLanguageToLocale(lang) {
  if (!lang) {
    return D_LANGUAGE
  }

  const language = lang?.match(/en(-US)?|es(-AR)?/i)
    ? lang.slice(0, 2)
    : D_LANGUAGE
  return language
}

export function loadLocaleMessages() {
  const locales = require.context(
    '@/locales',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  )

  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)

    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })

  return messages
}

export function saveLanguage(lang) {
  const languageBySession = localStorage.getItem(
    'interface-preferences--language'
  )

  const shouldGetSessionLanguage = !lang && languageBySession
  const shouldGetNavigatorLanguage = !lang && !languageBySession
  let reloadByNotEqSession = false

  if (shouldGetSessionLanguage) {
    lang = languageBySession
  }

  if (shouldGetNavigatorLanguage) {
    lang = calcLanguageToLocale(navigator.language || navigator.userLanguage)
  }

  if (languageBySession !== lang && !shouldGetNavigatorLanguage) {
    reloadByNotEqSession = true
  }

  localStorage.setItem('interface-preferences--language', lang)

  return { language: lang, reloadByNotEqSession }
}
