<template>
  <v-app>
    <v-main class="page">
      <v-container fluid>
        <transition>
          <router-view ref="PageView" class="full-height"></router-view>
        </transition>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { projectAuth, LocalStorageToken } from '@/firebase/config'

import { abilityHelper } from '@/helpers/ability'
import {
  calcLanguageToApi,
  calcLanguageToLocale,
  saveLanguage,
} from '@/helpers/locale'

import { debounce } from 'lodash'

const MainPageRouterName = 'ProfilePage'

export default {
  name: 'App',

  provide() {
    return {
      handleChangeLanguage: this.handleChangeLanguage,
      handleSyncLanguage: this.handleSyncLanguage,
      handleSignOut: this.handleSignOut,
    }
  },

  data() {
    return {
      callHandleSignIn: debounce(async function (payload) {
        await this.handleSignIn(payload)
      }, 250),
    }
  },

  created() {
    this.handleChangeLanguage(false)
  },

  mounted() {
    this.onAuthStateChangedByLogin()
  },

  methods: {
    onAuthStateChangedByLogin() {
      // wait for login
      this.$store.dispatch('signedInLogout')

      projectAuth.onAuthStateChanged(this.onAuthStateChangedNextOrObserver)
    },

    /**
     * @param {import('@firebase/auth').User} User
     */
    async onAuthStateChangedNextOrObserver(User) {
      if (!User) {
        this.handleLogout()

        this.callHandleSignIn.cancel()
        return
      }

      await this.$nextTick()

      this.callHandleSignIn({ refresh: true })
    },

    handleLogout() {
      this.$nextTick(() => {
        this.handleLoginPageMethods('setLoading', false)
      })

      this.$store.dispatch('signedInLogout')
      this.$store.dispatch('currentUser/logoutAccount')
    },

    async handleSignOut() {
      return new Promise(resolve => {
        projectAuth.signOut().then(() => {
          this.handleLogout()

          localStorage.removeItem(LocalStorageToken)

          if (!window.location.href.includes('login')) {
            window.location.assign(
              `${process.env.VUE_APP_ENGAGEMENT_LEGACY_LINK}/logout`
            )
          }

          resolve()
        })
      })
    },

    async handleSignIn(payload) {
      this.handleLoginPageMethods('setLoading', true)

      /* eslint-disable no-unused-vars */
      return this.$store
        .dispatch('currentUser/getAccountMe', payload)
        .then(data => {
          const lang = calcLanguageToLocale(data.language)
          this.handleChangeLanguage(lang)

          //abilityHelper([]) // @TODO Em Branco
          abilityHelper(data.permissions)

          this.$store.dispatch('signedInLogin')

          this.defineRouteGuardWatch()

          this.handleFromLoginToPage()

          this.handleSleek(data)
        })
        .catch(_error => {
          // eslint-disable-next-line no-console
          console.log(_error)

          this.handleLoginPageMethods('setLoading', false)
          this.handleLoginPageMethods('customErrorMessage')

          // User is signed out.
          this.handleSignOut()
        })
      /** eslint-enable no-unused-vars */
    },

    handleFromLoginToPage() {
      const from = sessionStorage.getItem('interface-recovery--redirectPath')

      if (from) {
        const { to, toQuery } = JSON.parse(from)
        sessionStorage.removeItem('interface-recovery--redirectPath')
        this.$router.push({ ...to, query: toQuery })

        return
      }

      if (!window.location.href.includes('login')) {
        return
      }

      this.$router.push({ name: 'Main' })
    },

    handleLoginPageMethods(methodName, ...payload) {
      this.$nextTick(() => {
        if (!window.location.href.includes('login')) {
          return
        }

        const method = this.$refs.PageView?.[methodName]
        if (typeof method !== 'function') {
          return
        }

        method(...payload)
      })
    },

    handleSleek(user) {
      const $sleek = window?.$sleek

      if (!$sleek) {
        return
      }

      const {
        email,
        name,
        planID,
        isAdmin = false,
        accountName,
        accountPeople,
      } = user

      $sleek.setUser({
        id: email,
        name,
        mail: email,
        token: email,
        weight: isAdmin ? 10 : 1,
      })

      $sleek.addMetadata({
        account_name: accountName,
        account_people: accountPeople,
        account_planID: planID,
        person_admin: isAdmin,
      })

      $sleek.hideButton()
    },

    defineRouteGuardWatch() {
      return this.$watch('$route.matched', this.handleRouteGuard, {
        immediate: true,
      })
    },

    async handleRouteGuard(matched) {
      const canPagePermissions = this.calcRoutePermissions(matched)
      const shouldGuardRoute = Object.keys(canPagePermissions).length
      const hasPermission = Object.values(canPagePermissions).every(
        permission => permission === true
      )

      if (shouldGuardRoute && !hasPermission) {
        this.goNextRoutePossible(MainPageRouterName)
      }

      return shouldGuardRoute && !hasPermission
    },

    /**
     * @param {Array} matched
     */
    calcRoutePermissions(matched) {
      const requiresPermissions = Array.from(
        new Set(matched.flatMap(record => record?.meta?.requiresPermission))
      ).filter(Boolean)

      const canPagePermissions = requiresPermissions.reduce(
        (obj, permission) => {
          const canPermission = this.$can('access', permission)
          return { ...obj, [permission]: canPermission }
        },
        {}
      )

      return canPagePermissions
    },

    /**
     * @param {String} RouteName
     */
    goNextRoutePossible(RouteName) {
      const PageRouter = this.$router.options.routes.find(
        r => r.name === RouteName
      )

      const canAccess = routePermission => {
        if (typeof routePermission !== 'boolean') {
          return Object.values(routePermission).some(childPermission =>
            canAccess(childPermission)
          )
        }

        return routePermission
      }

      const getRoutePermissions = Route =>
        Route?.children?.reduce((obj, page) => {
          if (page.path.includes(':')) {
            return { ...obj }
          }

          const matched = [Route, page]
          let hasPermission = Object.values(
            this.calcRoutePermissions(matched)
          ).every(permission => permission === true)

          if (page?.children) {
            const children = getRoutePermissions(page)

            const hasPermissionChildren = Object.values(children).some(
              permission => canAccess(permission)
            )

            hasPermission = hasPermissionChildren ? children : false
          }

          return { ...obj, [page.name]: hasPermission }
        }, {}) || {}
      const PageChilds = getRoutePermissions(PageRouter)

      let nextRoutePossible
      const getNextRoutePossible = Routes =>
        Object.keys(Routes).some(permission => {
          if (typeof Routes[permission] !== 'boolean') {
            return getNextRoutePossible(Routes[permission])
          }

          if (Routes[permission]) {
            nextRoutePossible = permission
          }

          return Routes[permission]
        })
      getNextRoutePossible(PageChilds)

      if (!nextRoutePossible) {
        return this.handleSignOut().then(() => {
          this.handleLoginPageMethods('customErrorMessage', {
            text: 'pageLogin.errorPermission.text',
          })
        })
      }

      setTimeout(() => {
        this.$router.push({ name: nextRoutePossible })
      }, 0)
    },

    /**
     * @param {String} lang
     */
    async handleSyncLanguage(lang) {
      const language = calcLanguageToApi(lang)

      return this.$store
        .dispatch('currentUser/putAccountMe', { language })
        .then(() => {
          this.handleChangeLanguage(lang)
        })
    },

    /**
     * @param {String} lang
     */
    async handleChangeLanguage(lang) {
      const { language, reloadByNotEqSession } = saveLanguage(lang)
      this.$root.$i18n.locale = language

      if (!reloadByNotEqSession) {
        return
      }

      const { l: urlLang = false } = this.$route.query
      const preventUrlNotEqSession = calcLanguageToLocale(urlLang) !== language
      if (urlLang && preventUrlNotEqSession) {
        return
      }

      window.location.reload()
    },
  },
}
</script>
