import { projectAuth } from '@/firebase/config'

const calcRouteQuery = ({ l = null, t = null, ...toQuery }) => {
  return {
    loginQuery: {
      ...(l && { l }),
      ...(t && { t }),
    },
    toQuery,
  }
}

export const beforeEachRouterEntry = async (to, _from, next) => {
  const currentUser = projectAuth.currentUser
  const requiresAuth = to.matched.some(record => record?.meta?.requiresAuth)

  const isRedirect = to?.meta?.external
  if (isRedirect) {
    let toURL = to.meta.external

    if (toURL.includes(':')) {
      const urlPath = toURL.split('/')
      const urlPatternParamsRequired = toURL
        .match(/:(\w+)/g)
        .map(param => param.slice(1))

      urlPatternParamsRequired.forEach(param => {
        if (to.params[param]) {
          const paramValue = to.params[param]
          const paramIndex = urlPath.indexOf(`:${param}`)

          if (paramIndex > -1) {
            urlPath[paramIndex] = paramValue
          }
        }
      })

      toURL = urlPath.join('/')
    }

    window.location.assign(toURL)
    return
  }

  if (requiresAuth && !currentUser && to.name !== 'Login') {
    const { loginQuery, toQuery } = calcRouteQuery(to.query)

    if (!to.path.includes('/login')) {
      sessionStorage.setItem(
        'interface-recovery--redirectPath',
        JSON.stringify({ to, toQuery })
      )
    }

    if (_from.name === 'Login') {
      return
    }

    return next({ name: 'Login', query: loginQuery })
  }

  if (!requiresAuth && currentUser) {
    return next({ name: 'Main' })
  }

  return next()
}

export default { beforeEachRouterEntry }
