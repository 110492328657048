import axios from 'axios'
import { projectAuth } from '@/firebase/config'

import {
  onFulfilledResponse,
  onRejectedResponse,
} from '@/helpers/axios/interceptor-response'

import { onFulfilledRequest } from '@/helpers/axios/interceptor-request'

const MAX_CONTENT_LENGTH = 50000000 // 50MB

/**
 * @returns {import('axios').AxiosInstance}
 */
const api = async () => {
  return new Promise((resolve, reject) => {
    projectAuth.onAuthStateChanged(async user => {
      try {
        const token = await user?.getIdToken()

        const http = axios.create({
          baseURL: process.env.VUE_APP_API_BASE_URL,
          maxContentLength: MAX_CONTENT_LENGTH,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            // 'X-Apigateway-Api-Userinfo': process.env.VUE_APP_API_TEST_TOKEN,
          },
        })

        http.interceptors.response.use(
          response => onFulfilledResponse(response),
          error => onRejectedResponse(error)
        )

        http.interceptors.request.use(config => onFulfilledRequest(config))

        resolve(http)
      } catch (error) {
        reject(error)
      }
    })
  })
}

export default api
