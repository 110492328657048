export const growth = 'growth'
export const growth_personal_development_plans =
  'growth.personal-development-plans'
export const growth_personal_development_plans_access =
  'growth.personal-development-plans.access'
export const growth_personal_development_plans_create =
  'growth.personal-development-plans.create'
export const growth_personal_development_plans_edit =
  'growth.personal-development-plans.edit'
export const growth_personal_development_plans_fetch =
  'growth.personal-development-plans.fetch'
export const growth_assessments_report = 'growth.assessments.report'
