const LoginPage = () =>
  import(/* webpackChunkName: "LoginPage" */ '@/pages/Login/Login.vue')
const NotFoundPage = () =>
  import(/* webpackChunkName: "NotFoundPage" */ '@/pages/NotFound/NotFound.vue')

const ProfilePage = () =>
  import(/* webpackChunkName: "ProfilePage" */ '@/views/Profile/Profile.vue')
const NewHome = () =>
  import(/* webpackChunkName: "NewHome" */ '@/pages/NewHome/NewHome.vue')

import * as _permissions from '@/helpers/ability/permissions'

export const moduleRoutes = [
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
  },
  {
    path: '/',
    name: 'Main',
    redirect: { name: 'ProfilePage' },
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFoundPage,
  },

  {
    path: '/',
    name: 'ProfilePage',
    redirect: { name: 'home' },
    component: ProfilePage,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'home',
        name: 'home',
        component: NewHome,
        meta: {
          title: 'pageNavigation.profile.routes.Overview',
        },
      },
    ],
  },

  {
    path: '/engagement',
    name: 'EngagementPage',
    meta: {
      external: '/engagement',
    },
    children: [
      {
        path: 'survey',
        name: 'SurveyInit',
        meta: {
          external: '/engagement/survey',
          requiresPermission: _permissions.engagement_survey,
        },
      },
      {
        path: 'climate',
        name: 'ClimatePage',
        meta: {
          external: '/engagement/climate',
        },
      },
      {
        path: 'opinions/me',
        name: 'MyOpinions',
        meta: {
          external: '/engagement/opinions/me',
        },
      },
      {
        path: 'opinions/me/new',
        name: 'MyOpinionsNew',
        meta: {
          external: '/engagement/opinions/me/new',
        },
      },
      {
        path: 'opinions/received-opinions',
        name: 'ReceivedOpinionsPage',
        meta: {
          external: '/engagement/opinions/received-opinions',
        },
      },
      {
        path: 'actions-plans',
        name: 'ActionPlansPage',
        meta: {
          external: '/engagement/action-plan/action-plans',
        },
      },
    ],
  },
  {
    path: '/growth',
    name: 'GrowthPage',
    meta: {
      external: '/growth',
    },
    children: [
      {
        path: 'one-on-one',
        name: 'OneOnOnePage',
        meta: {
          external: '/growth/one-on-one',
        },
      },
      {
        path: 'group',
        name: 'GroupReport',
        meta: {
          external: '/growth/reviews/report/group',
          requiresPermission: _permissions.growth_assessments_report,
        },
      },
      {
        path: 'follow-up',
        name: 'FollowUpPage',
        meta: {
          external: '/growth/reviews/follow-up',
          requiresPermission: _permissions.growth_assessments_report,
        },
      },
      {
        path: 'report/me',
        name: 'MyReportPage',
        meta: {
          external: '/growth/reviews/report/me',
          requiresPermission: _permissions.profile_growth_report,
        },
      },
      {
        path: 'invitations/me',
        name: 'MyInvitations',
        meta: {
          external: '/growth/reviews/invitations/me',
          requiresPermission: _permissions.profile_growth_invites,
        },
      },
      {
        path: 'pdis',
        name: 'PDIsPage',
        meta: {
          external: '/growth/pdis',
          requiresPermission:
            _permissions.profile_growth_personal_development_plans_access,
        },
      },
      {
        path: 'feedbacks',
        name: 'FeedbacksPage',
        meta: {
          external: '/growth/feedbacks',
          requiresPermission: _permissions.profile_feedback,
        },
      },
    ],
  },
  {
    path: '/performance',
    name: 'PerformancePage',
    meta: {
      external: process.env.VUE_APP_PERFORMANCE_LINK,
    },
    children: [
      {
        path: 'dashboard',
        name: 'DashboardPage',
        meta: {
          external: process.env.VUE_APP_PERFORMANCE_LINK + '/dashboard',
          requiresPermission: _permissions.performance_dashboards,
        },
        children: [
          {
            path: 'overview',
            name: 'OverviewPage',
            meta: {
              external:
                process.env.VUE_APP_PERFORMANCE_LINK + '/dashboard/overview',
              requiresPermission: _permissions.performance_dashboards_overview,
            },
          },
          {
            path: 'management-report',
            name: 'ManagementReportPage',
            meta: {
              external:
                process.env.VUE_APP_PERFORMANCE_LINK +
                '/dashboard/management-report',
              requiresPermission:
                _permissions.performance_dashboards_managementReports,
            },
          },
        ],
      },
      {
        path: 'okrs',
        name: 'OKRsPage',
        meta: {
          external: process.env.VUE_APP_PERFORMANCE_LINK + '/okrs',
        },
        children: [
          {
            path: 'treeview',
            name: 'TreeViewPage',
            meta: {
              external: process.env.VUE_APP_PERFORMANCE_LINK + '/okrs/treeview',
            },
          },
          {
            path: 'listview',
            name: 'ListViewPage',
            meta: {
              external: process.env.VUE_APP_PERFORMANCE_LINK + '/okrs/listview',
            },
          },
        ],
      },
      {
        path: 'initiatives',
        name: 'InitiativesPage',
        meta: {
          external: process.env.VUE_APP_PERFORMANCE_LINK + '/initiatives',
        },
        children: [
          {
            path: 'all',
            name: 'AllPage',
            meta: {
              external:
                process.env.VUE_APP_PERFORMANCE_LINK + '/initiatives/all',
            },
          },
          {
            path: 'coresponsibility-requests',
            name: 'CoresponsibilityRequestsPage',
            meta: {
              external:
                process.env.VUE_APP_PERFORMANCE_LINK +
                '/initiatives/coresponsibility-requests',
            },
          },
        ],
      },
    ],
  },
  {
    path: '/custom-survey',
    name: 'CustomSurveyPage',
    meta: {
      external: '/custom-survey',
      requiresPermission: _permissions.customSurvey,
    },
    children: [
      {
        path: 'manage',
        name: 'ManagePage',
        meta: {
          external: '/custom-survey/manage',
          requiresPermission: _permissions.profile_home_custom_survey_list,
        },
      },
      {
        path: 'results/:customSurveyID',
        name: 'ReportPage',
        meta: {
          external: '/custom-survey/results/:customSurveyID',
          requiresPermission: _permissions.profile_home_custom_survey_results,
        },
      },
      {
        path: 'opinions/:customSurveyID/home',
        name: 'OpinionsPage',
        meta: {
          external: '/custom-survey/opinions/:customSurveyID/home',
          requiresPermission: _permissions.profile_home_custom_survey_opinions,
        },
      },
      {
        path: 'opinions/:customSurveyID/team',
        name: 'OpinionsTeamPage',
        meta: {
          external: '/custom-survey/opinions/:customSurveyID/team',
          requiresPermission:
            _permissions.profile_home_custom_survey_opinions_group,
        },
      },
      {
        path: 'survey/:customSurveyID',
        name: 'SurveyPage',
        meta: {
          external: '/custom-survey/survey/:customSurveyID',
          requiresPermission: _permissions.profile_home_custom_survey,
        },
      },
    ],
  },
]
