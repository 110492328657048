import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import { UserModule } from './modules/user'

import { SideModule } from './modules/side'
import { DialogModule } from './modules/dialog'

/**
 * @type {import('vuex').Store}
 */
export default new Vuex.Store({
  modules: {
    currentUser: UserModule,

    Side: SideModule,
    Dialog: DialogModule,
  },

  state: {
    Page: {
      isSignedIn: false,
      currentTab: '',

      HeaderAside: {
        id: null,
        constructors: new Map(),
      },
    },

    avatar: {
      idToColors: {},
    },
  },

  mutations: {
    SET_page_isSignedIn(state, payload) {
      state.Page.isSignedIn = payload
    },
    SET_page_currentTab(state, payload) {
      state.Page.currentTab = payload
    },

    SET_page_HeaderAside(state, payload) {
      const { id, constructor } = payload

      Vue.set(state.Page.HeaderAside, 'id', id)
      state.Page.HeaderAside.constructors.set(id, constructor)
    },

    SET_avatar_idToColors(state, payload) {
      if (state.avatar.idToColors[`${payload.idx}`]) {
        return
      }

      state.avatar.idToColors[`${payload.idx}`] = payload.color
    },
  },

  actions: {
    signedInLogin({ commit }) {
      commit('SET_page_isSignedIn', true)
    },
    signedInLogout({ commit }) {
      commit('SET_page_isSignedIn', false)
    },

    navigation({ commit }, payload) {
      commit('SET_page_currentTab', payload)
    },

    displayHeaderAside({ dispatch, commit, state }, payload) {
      if (!(state.Page.HeaderAside.constructors instanceof Map)) {
        state.Page.HeaderAside.constructors = new Map()
      }

      if (payload?.id === null) {
        dispatch('removeHeaderAside')
        return
      }

      if (
        payload?.refresh === true ||
        !state.Page.HeaderAside.constructors.has(payload.id)
      ) {
        Vue.component(payload.id, payload.constructor)
      }

      commit('SET_page_HeaderAside', payload)
    },
    removeHeaderAside({ commit }) {
      commit('SET_page_HeaderAside', {
        id: null,
        constructor: null,
      })
    },
  },

  getters: {
    isSignedIn: ({ Page }) => Page.isSignedIn,
    currentTab: ({ Page }) => Page.currentTab,

    HeaderAsideID: ({ Page }) => Page.HeaderAside.id,

    AvatarIdToColors: state => state.avatar.idToColors || {},
  },
})
