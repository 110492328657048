/**
 * @type {import('vuex').Module}
 */
export const DialogModule = {
  namespaced: false,

  state: () => ({}),

  getters: {},

  actions: {},

  mutations: {},
}
